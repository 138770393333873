/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.form-new-agreement .actions-toolbar {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .form-new-agreement .fieldset .legend,
  .form-new-agreement .actions-toolbar {
    margin-left: 0;
  }
}

.paypal.after:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 0.5rem 0.5rem 1rem;
  text-transform: uppercase;
}

.paypal.before:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 1rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
}

.paypal.acceptance {
  display: block;
  margin: 0 0 0.5rem;
}

.paypal.acceptance img {
  max-width: 100%;
}

.box-tocart .paypal,
.block-minicart .paypal {
  margin-top: 1rem;
  text-align: center;
}

.box-tocart .paypal img,
.block-minicart .paypal img {
  display: block;
  margin: 0 auto;
}

.paypal-button-widget .paypal-button {
  margin-top: 0.5rem;
}

.paypal-express-in-context-mini-cart-container {
  max-width: 223px;
  margin-left: auto;
  margin-right: auto;
}

.catalog-product-view.paypal-enabled .box-tocart {
  margin-bottom: 170px;
}

.catalog-product-view.paypal-enabled .actions > button,
.catalog-product-view.paypal-enabled .actions > div {
  margin-bottom: 1rem;
}

.catalog-product-view.paypal-enabled .product-info-price {
  margin-bottom: 200px;
}
